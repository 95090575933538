import { default as firstrun9BPOQypPiZMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/firstrun.vue?macro=true";
import { default as forgot_45passwordB3U8uytfuMMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/forgot-password.vue?macro=true";
import { default as indexi1yXBytoKLMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/index.vue?macro=true";
import { default as loginUslnvNIMI9Meta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/login.vue?macro=true";
import { default as meet_45the_45teamvuFmJABy9gMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/meet-the-team.vue?macro=true";
import { default as _91_91filter_93_93O6RcFO6rFmMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue?macro=true";
import { default as addpmnMBqTwvxMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/movers/add.vue?macro=true";
import { default as _91id_93Ov8DZ9pfChMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/movers/edit/[id].vue?macro=true";
import { default as indexCsLU1xTUf5Meta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/movers/index.vue?macro=true";
import { default as _91_91step_93_935RNk7iIedrMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue?macro=true";
import { default as _91_91step_93_93k38wkNDL0mMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue?macro=true";
import { default as index5yNWjZArU4Meta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/index.vue?macro=true";
import { default as pending7RIvW5iZeTMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/pending.vue?macro=true";
import { default as _91id_932EvAuhCkzQMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/view/[id].vue?macro=true";
import { default as _91_91step_93_93l0uEX5WznGMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue?macro=true";
import { default as indexfz4ZLPuNm1Meta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/void/index.vue?macro=true";
import { default as addhIzZbH9C63Meta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/partner-users/add.vue?macro=true";
import { default as _91id_937blMK64QCLMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue?macro=true";
import { default as indexJgZoOBE3o2Meta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/partner-users/index.vue?macro=true";
import { default as _91token_93PVGI5xCX2yMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/password/reset/[token].vue?macro=true";
import { default as addhhOieVKmbcMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/properties/add.vue?macro=true";
import { default as _91id_93QI2Y2PDAfVMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/properties/edit/[id].vue?macro=true";
import { default as indexufEybbyRErMeta } from "/home/forge/maverick.app.jmi.cloud/apps/partner/pages/properties/index.vue?macro=true";
export default [
  {
    name: "firstrun",
    path: "/firstrun",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/firstrun.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordB3U8uytfuMMeta || {},
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginUslnvNIMI9Meta || {},
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "meet-the-team",
    path: "/meet-the-team",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/meet-the-team.vue").then(m => m.default || m)
  },
  {
    name: "move-notifications-index-filter",
    path: "/move-notifications/:filter?",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: "movers-add",
    path: "/movers/add",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/movers/add.vue").then(m => m.default || m)
  },
  {
    name: "movers-edit-id",
    path: "/movers/edit/:id()",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/movers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "movers",
    path: "/movers",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/movers/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-add-step",
    path: "/moves/add/:step?",
    meta: _91_91step_93_935RNk7iIedrMeta || {},
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-edit-id-step",
    path: "/moves/edit/:id()/:step?",
    meta: _91_91step_93_93k38wkNDL0mMeta || {},
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves",
    path: "/moves",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-pending",
    path: "/moves/pending",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/pending.vue").then(m => m.default || m)
  },
  {
    name: "moves-view-id",
    path: "/moves/view/:id()",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "moves-void-id-step",
    path: "/moves/void/:id()/:step?",
    meta: _91_91step_93_93l0uEX5WznGMeta || {},
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-void",
    path: "/moves/void",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/moves/void/index.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-add",
    path: "/partner-users/add",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/partner-users/add.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-edit-id",
    path: "/partner-users/edit/:id()",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "partner-users",
    path: "/partner-users",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/partner-users/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93PVGI5xCX2yMeta || {},
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "properties-add",
    path: "/properties/add",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/properties/add.vue").then(m => m.default || m)
  },
  {
    name: "properties-edit-id",
    path: "/properties/edit/:id()",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/properties/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "properties",
    path: "/properties",
    component: () => import("/home/forge/maverick.app.jmi.cloud/apps/partner/pages/properties/index.vue").then(m => m.default || m)
  }
]